import React from "react"
import { Link } from "gatsby"
import { breadcrumbs as breadcrumbsClass, hasDropdown } from "./breadcrumb-clean.module.css"
import { Helmet } from "react-helmet"
import linkBuilder from "../../modules/linkBuilder"

const BreadcrumbClean = ({ article, desk, deskCategory }) => {
  const breadcrumbs = []

  breadcrumbs.push({
    name: "Home",
    link: "/",
  })

  if (desk) {
    breadcrumbs.push({
      name: desk.name,
      link: linkBuilder.desk(desk),
      class: "desk",
    })
  }

  if (deskCategory) {
    breadcrumbs.push({
      name: deskCategory.name,
      link: linkBuilder.deskCategory(deskCategory),
      class: "category",
    })
  }

  // if (article) {
  //   breadcrumbs.push({
  //     name: article.title
  //   })
  // }

  let breadcrumbHtml = (
    <div className={breadcrumbsClass}>
      <ol id="breadcrumb">
        {breadcrumbs.map((item, index) => {
          if (index === 0)
            return (
              <li className="home" key={`breadcrumb-${index}`}>
                <Link to={item.link}>Home</Link>
              </li>
            )
          else
            return (
              <li
                className={`${hasDropdown} ${item.class || ""}`}
                key={`breadcrumb-${index}`}
              >
                <Link to={item.link}>{item.name}</Link>
              </li>
            )
        })}
      </ol>
    </div>
  )

  // Build ld+json
  const itemListElement = []
  for (let index in breadcrumbs) {
    if (parseInt(index) !== breadcrumbs.length - 1)
      itemListElement.push({
        "@type": "ListItem",
        position: parseInt(index) + 1,
        item: {
          "@id": `https://mkbservicedesk.nl` + breadcrumbs[index].link,
          name: breadcrumbs[index].name,
        },
      })
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": ${JSON.stringify(itemListElement)}
        }
      `}</script>
      </Helmet>
      {breadcrumbHtml}
    </>
  )
}

export default BreadcrumbClean
