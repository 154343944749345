import firebase from "gatsby-plugin-firebase"
import { trackEvent } from "../modules/analytics";


export const getRating = async (
  uid,
  articleId
) => {
  try {
    const userRating = await firebase.firestore()
      .collection("contentfulEntries")
      .doc(articleId)
      .collection("ratings")
      .doc(uid)
      .get();

    return userRating.data();
  } catch (error) {
    console.log(error);
  }
}

export const updateRating = async (uid, articleId, rating = null, feedbackMessage = null) => {
  try {
    const { serverTimestamp } = firebase.firestore.FieldValue;
    const ratingData = { date: serverTimestamp(), processed: false };

    if (rating !== null && typeof rating === 'number') {
      ratingData.rating = rating;
      trackEvent('feedback_given')
    }

    if (feedbackMessage === null || feedbackMessage === '') {
      ratingData.feedback = null;
      ratingData.hasFeedback = false;
    } else {
      ratingData.feedback = feedbackMessage;
      ratingData.hasFeedback = true;
    }

    const userRatingRef = firebase.firestore()
      .collection("contentfulEntries")
      .doc(articleId)
      .collection("ratings")
      .doc(uid);

    await userRatingRef.set(ratingData, { merge: true });

    return {};

  } catch (error) {
    console.error(error);
  }
};