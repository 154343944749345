import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  AuthorWrapper,
  AuthorLeft,
  AuthorAvatar,
  AuthorText,
  AuthorLabel,
  AuthorBio,
} from "./author.module.css"
import linkBuilder from "../../modules/linkBuilder"
import { Link } from "gatsby"

const Author = ({ author }) => {
  return (
    <div className={AuthorWrapper}>
      <div className={AuthorLeft}>
        <div className={AuthorAvatar}>
          {author.image && (
            <GatsbyImage
              image={author.image.gatsbyImageData}
              alt={author.name}
            />
          )}
        </div>
      </div>

      <div className="AuthorRight">
        <div className={AuthorText}>
          <p className={AuthorLabel}>Auteur</p>
          {author.authorPage && author.slug && (
            <Link rel="nofollow" to={linkBuilder.author(author)}>
              <h6>{author.name}</h6>
            </Link>
          )}
          {(!author.authorPage || !author.slug) && <h6>{author.name}</h6>}
          <p className={AuthorBio}>
            {author.description && (
              <>{author.description.childMarkdownRemark.rawMarkdownBody}</>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Author
