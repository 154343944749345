import React from "react"
import { TocWrapper, TocTitle } from "./table-of-contents.module.css"

const TableOfContents = ({ body }) => {
  body = JSON.parse(body)
  const tocArray = []
  for (let element of body.content) {
    if (
      element.nodeType === "heading-2" &&
      element.content.length === 1 &&
      element.content[0].nodeType === "text" &&
      element.content[0].value !== ""
    ) {
      let headerId = element.content[0].value
        .replace(/[^a-z0-9A-Z]/g, "")
        .toLowerCase()
      if (headerId !== "")
        tocArray.push(
          <li key={`toc-${tocArray.length + 1}`}>
            <a
              href={`#toc-${element.content[0].value
                .replace(/[^a-z0-9A-Z]/g, "")
                .toLowerCase()}`}
            >
              {element.content[0].value}
            </a>
          </li>
        )
    }
  }
  if (tocArray.length <= 1) return <></>
  return (
    <div className={TocWrapper}>
      <p className={TocTitle}>Inhoud:</p>
      <ul className="TocList">{tocArray}</ul>
    </div>
  )
}

export default TableOfContents
