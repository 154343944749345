import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ExpertWrapper,
  ExpertLeft,
  ExpertAvatar,
  ExpertText,
  ExpertLabel,
  ExpertBio,
} from "./expert.module.css"
import linkBuilder from "../../modules/linkBuilder"
import { Link } from "gatsby"

const Expert = ({ expert }) => {
  return (
    <div className={ExpertWrapper}>
      <div className={ExpertLeft}>
        <div className={ExpertAvatar}>
          {expert.image && (
            <GatsbyImage
              image={expert.image.gatsbyImageData}
              alt={expert.name}
            />
          )}
        </div>
      </div>

      <div className="ExpertRight">
        <div className={ExpertText}>
          <p className={ExpertLabel}>Expert</p>
          {expert.expertPage && expert.slug && (
            <Link rel="nofollow" to={linkBuilder.expert(expert)}>
              <h6>{expert.name}</h6>
            </Link>
          )}
          {(!expert.expertPage || !expert.slug) && <h6>{expert.name}</h6>}
          <p className={ExpertBio}>
            {expert.description && (
              <>{expert.description.childMarkdownRemark.rawMarkdownBody}</>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Expert
