import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  sponsorLogo,
  sponsorContainer,
  sponsorTitle,
  sponsorContent,
  shortDescriptionWrapper,
} from "./footer-sponsor.module.css"
import { trackExternalLink } from "../../services/tracking"
import { getUid } from "../../services/auth"
import { dataLayer } from "../../modules/analytics"
import MediaImage from "../media-image/media-image"
import RichTextContent from "../rich-text-content/rich-text-content"

const FooterSponsor = ({ sponsor }) => {
  const trackEvent = async () => {
    dataLayer({
      event: "website-interaction",
      category: "sponsorbox",
      action: `external-link:${sponsor.url}`,
      label: sponsor.name,
    })
    trackExternalLink(await getUid(), sponsor.contentful_id, sponsor.url)
  }
  if (!sponsor) return null
  return (
    <div className={sponsorContainer}>
      <p className={sponsorTitle}>Mogelijk gemaakt door onze kennispartner:</p>
      <div className={sponsorContent}>
        <a
          id="sponsor"
          href={sponsor.url}
          rel="sponsored noopener noreferrer"
          target="_blank"
          onClick={trackEvent}
        >
          {sponsor.image && (
            <MediaImage
              image={sponsor.image}
              className={sponsorLogo}
              alt={sponsor.name}
            />
          )}
        </a>
        
        <div className={shortDescriptionWrapper}>
          {sponsor.shortDescription ? (
            <RichTextContent content={sponsor.shortDescription} />
          ) : (
            <p>{sponsor.description.childMarkdownRemark.rawMarkdownBody}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterSponsor
