import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import {
    feedbackTitle,
    feedbackContainer,
    feedbackTextarea,
    ratingWrapper,
    starsContainer,
    starStatusText,
    feedbackButton,
    starIcon,
    reviewSubmitResponse
} from "./article-rating.module.css";

import { updateRating, getRating } from "../../services/rating";
import { getUid } from "../../services/auth"

const statusMessages = {
    1: { status: "Slecht", message: "Laat ons weten hoe we kunnen verbeteren" },
    2: { status: "Niet zo goed", message: "We streven naar beter" },
    3: { status: "Voldoende", message: "Bedankt voor je feedback" },
    4: { status: "Goed", message: "Geweldig om te horen dat je tevreden bent" },
    5: { status: "Uitstekend", message: "Fantastisch! Bedankt voor je steun" },
};

const ArticleRating = ({ contentfulId }) => {

    return <StarRating contentfulId={contentfulId} />;
};

const StarRating = ({ contentfulId }) => {
    const [rating, setRating] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [showText, setShowText] = useState(false);
    const [showThanksMessage, setShowThanksMessage] = useState(false)
    const [starCount, setStarCount] = useState(null);
    const [Uid, setUid] = useState(null);
    const [starStatus, setStarStatus] = useState({ status: "", message: "" });

    useEffect(() => {
        async function fetchData() {
            try {
                const id = await getUid();
                setUid(id);
                const ratingData = await getRating(id, contentfulId);
                if (ratingData?.rating) {
                    setRating(ratingData.rating)
                    setShowText(true)
                };
                if (ratingData?.feedback) setFeedbackMessage(ratingData.feedback);
            } catch (error) {
                console.log(error)
            }
        }

        fetchData();
    }, []);

    const handleStarClick = (index) => {
        setShowThanksMessage(false)
        setShowText(true)
        const newRating = index * 20;
        setRating(newRating);

        if (Uid && contentfulId && newRating !== null) {
            updateRating(Uid, contentfulId, newRating)
        }
    }

    const sendFeedback = async (Uid, contentfulId, rating, feedbackMessage) => {
        try {
            await updateRating(Uid, contentfulId, rating, feedbackMessage)
            setShowText(false);
            setShowThanksMessage(true);
        } catch (err) {
            console.log(err)
        }
    }

    const handleStarHover = (index) => {
        setRating(index * 20);
    }

    useEffect(() => {
        setStarStatus(statusMessages[rating / 20] || { status: "", message: "" });
        setStarCount((prevRating) => (prevRating === rating / 20 ? 0 : rating / 20));
    }, [rating]);

    return (
        <div className={feedbackContainer}>
            <p className={feedbackTitle}>Wat vind je van dit artikel?</p>
            {/* <p className={feedbackDescription}>
                We willen graag weten wat je van onze artikelen vindt! Laat ons
                alsjeblieft weten hoe we het doen door een beoordeling te geven met 1
                tot 5 sterren. Of je nou dolenthousiast bent of denkt dat er ruimte is
                voor verbetering, we horen het graag van je. Bedankt voor je tijd en
                moeite om ons te helpen groeien!
            </p> */}

            {/* Star rating & Short rating text */}
            <div className={ratingWrapper}>
                <div className={starsContainer}>
                    {[...Array(5)].map((_, index) => (
                        <FaStar
                            key={index}
                            // title="Double click to unselect"
                            className={starIcon}
                            size="50"
                            color={index < starCount ? "#EE8000" : "#D2D2D2"}
                            onClick={() => handleStarClick(index + 1)}
                            onMouseOver={() => handleStarHover(index + 1)}
                        />
                    ))}
                </div>
                <span className={starStatusText}>{starStatus.status}</span>
            </div>

            {showThanksMessage && <>
                <br />
                <span className={reviewSubmitResponse}>Bedankt voor je feedback! 👍</span>
            </>}

            {/* Feedback form & Long rating text */}
            <>
                {showText &&
                    <>
                        <textarea value={feedbackMessage} onChange={(e) => setFeedbackMessage(e.target.value)} className={feedbackTextarea} placeholder={`${statusMessages[rating / 20].message} Je kunt je feedback verzenden zonder aanvullende opmerkingen. Maar je helpt ons wel enorm met een kleine toelichting. Let op: Vul hier geen persoonlijke gegevens in.`} />
                        <button onClick={() => sendFeedback(Uid, contentfulId, rating, feedbackMessage)} className={feedbackButton}>Verzenden</button>
                    </>
                }</>
        </div>
    );
};

export default ArticleRating;
