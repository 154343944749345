import React from "react"
import Helmet from "react-helmet"
import RichTextContent from "../rich-text-content/rich-text-content"
import { faq as faqClass } from "./faq.module.css"
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const Faq = ({ faq, visible }) => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faq.map((item) => ({
              "@type": "Question",
              name: item.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: documentToPlainTextString(JSON.parse(item.answer?.raw)),
              },
            })),
          })}
        </script>
      </Helmet>
      {visible && <section className={faqClass}>
        <h3>Veelgestelde vragen</h3>
        {faq.map((item) => {
          return (
            <>
              <h4>{item.question}</h4>
              <RichTextContent content={item.answer} />
            </>
          )
        })}
      </section>}
    </>
  )
}

export default Faq
