import PropTypes from "prop-types"
import React, { Component } from "react"

const scrollStyle = (
  width
) => ({
  margin: 0,
  padding: 0,
  position: "fixed",
  opacity: 0.7,
  top: 0,
  zIndex: "1000000",
  backgroundColor: `#ee8000`,
  height: `5px`,
  width: `${width}`,
  transitionProperty: "width",
  transitionDuration: `1s`,
  transitionTimingFunction: `ease-out`,
})

class ScrollProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: null,
    }
    this.Scrolling = this.Scrolling.bind(this)
  }

  Scrolling() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const height =  document.getElementById("main-article").offsetHeight - window.innerHeight;
    const scrolled = (winScroll / height) * 100
    if (height > 0) {
      this.setState({ width: `${scrolled}%` })
    } else {
      this.setState({ width: null })
    }
  }

  componentDidMount() {
    try {
      window.addEventListener("scroll", this.Scrolling)
    } catch (oError) {
      console.log(oError)
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("scroll", this.Scrolling)
    } catch (oError) {
      console.log(oError)
    }
  }

  render() {
    const { width } = this.state
    return (
      <div
        className="scroll-progress"
        style={scrollStyle(width)}
      />
    )
  }
}

ScrollProgressBar.propTypes = {
  height: PropTypes.number,
  duration: PropTypes.number,
  color: PropTypes.string.isRequired,
}

export default ScrollProgressBar
