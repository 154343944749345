import React, { useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import { Formik, Form, Field, ErrorMessage } from "formik"
import {
  newsletterWrapper,
  newsletterInner,
  title,
  formFieldWrapper,
  formFieldError,
  mailchimpResponse,
  submitWrapper,
  formWrapper
} from "./cta-newsletter.module.css"
import { getUser } from "../../services/users"
import { signup } from "../../services/newsletter"
import { trackEvent } from "../../modules/analytics"

const CtaNewsletterComponent = ({ position }) => {
  position = position || 0
  const [mcResponse, setMcResponse] = useState(null)
  const [user, setUser] = useState({})

  const fieldsInitialValues = {
    optin: false,
    email: "",
  }

  const [initialValues, setInitialValues] = useState(fieldsInitialValues)

  useEffect(() => {
    if (!firebase) return
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) return await firebase.auth().signInAnonymously()
      const userData = await getUser(user.uid)
      if (userData) setInitialValues(userData)
      setUser(user)
    })
  }, [])

  const handleSubmit = async (formData) => {
    if (formData.email) {
      let response = await signup(user.uid, formData.email)
      setMcResponse(response)
      if (response) {
        trackEvent("cta_newsletter_signup")
      }
    }
  }

  return (
    <div className={newsletterWrapper}>
      <div className={newsletterInner}>

        <p className={title}>
          Altijd op de hoogte van het laatste ondernemersnieuws?
        </p>

        <p className="text">Meld je aan voor onze nieuwsbrief</p>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form className={formWrapper}>
            <div className={formFieldWrapper} key="email">
              <Field
                validate={(value) =>
                  value ? undefined : "E-mailadres is verplicht"
                }
                className="formField"
                placeholder="Jouw e-mailadres"
                type="email"
                name="email"
              />
            </div>

            <div className={submitWrapper}>
              <button type="submit">Aanmelden</button>
            </div>
          </Form>
        </Formik>

        {mcResponse === true && (
          <p className={mailchimpResponse}>
            Bedankt! Je bent aangemeld voor de nieuwsbrief
          </p>
        )}

        {mcResponse === false && (
          <p className={mailchimpResponse}>
            Aanmelden mislukt. Probeer het opnieuw of gebruik een ander
            e-mailadres
          </p>
        )}
      </div>
    </div>
  )
}

export default CtaNewsletterComponent
