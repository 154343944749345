import React from "react"
import {
  RelatedHubsWrapper,
  RelatedHubs,
  RelatedHubList,
} from "./related-categories.module.css"
import linkBuilder from "../../modules/linkBuilder"
//import ArticleCardClean from "../article-card-clean/article-card-clean"
import { Link } from "gatsby"

const RelatedCategories = ({ categories }) => {
  return (
    <div className={RelatedHubsWrapper}>
      <div className={RelatedHubs}>
        {categories.slice(0, 3).map((category, index) => {
          const articles = category.articles?.data?.allContentfulArticle?.nodes
          if (!articles) return <></>
          return (
            <div className={RelatedHubList}>
              <h6>
                <Link to={linkBuilder.deskCategory(category)}>
                  Meer over:
                  <br />
                  {category.name}
                </Link>
              </h6>
              <ul>
                {articles.slice(0, 5).map((article, index) => {
                  return (
                    <li>
                      <Link to={linkBuilder.article(article)}>
                        {article.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RelatedCategories
