import React from "react"
import { ctaWrapper } from "./cta-article-intro.module.css"
import linkBuilder from "../../modules/linkBuilder"
import EntryCard from "../entry-card/entry-card"
import { eventTracking } from "../../services/tracking"

const CtaArticleIntro = ({
  callToActions,
  displayPageContentfulId,
  displayPageTitle,
  displayPagePath,
  displayPosition,
}) => {
  const allowedTypes = [
    "ContentfulExternalLink",
    "ContentfulProductBeta",
    "ContentfulDownload",
  ]
  const maxBoxes = 2

  const renderEntryCard = (ctaItem) => {
    const title = ctaItem.callToActionTitle || ctaItem.title || ctaItem.name;
    let link;
    let contentType;
    switch (ctaItem.__typename) {
      case "ContentfulProductBeta":
        link = linkBuilder.product(ctaItem)
        contentType = "product";
        break
      case "ContentfulDownload":
        link = linkBuilder.download(ctaItem)
        contentType = "download";
        break
      case "ContentfulExternalLink":
        link = ctaItem.url
        contentType = "externalLink";
        break
      default:
        link = null;
    }

    const image = ctaItem.callToActionImage || ctaItem.image;

    const eventTrackingData = {
      contentfulId: ctaItem.contentful_id,
      contentfulType: contentType,
      contentfulTitle: ctaItem.title,
      displayPageContentfulId,
      displayPageTitle,
      displayPagePath,
      displayPosition,
    }

    if (image?.gatsbyImageData) {
      return (
        <EntryCard
          key={`cta-article-intro-${ctaItem.id}`}
          title={title}
          link={link}
          image={image}
          style={{ height: "100%" }}
          onClick={() => {
            eventTracking("click", eventTrackingData)
          }}
        />
      )
    } else {
      return (
        <EntryCard
          key={`cta-article-intro-${ctaItem.id}`}
          title={title}
          link={link}
          imageUrl={image?.file?.url}
          style={{ height: "100%" }}
          onClick={() => {
            eventTracking("click", eventTrackingData)
          }}
        />
      )
    }
  }

  return (
    <div className={ctaWrapper}>
      {callToActions
        .filter((cta) => allowedTypes.includes(cta.__typename))
        .slice(0, maxBoxes)
        .map(renderEntryCard)}
    </div>
  )
}

export default CtaArticleIntro
