// extracted by mini-css-extract-plugin
export var feedbackButton = "article-rating-module--feedbackButton--fdd8b";
export var feedbackContainer = "article-rating-module--feedbackContainer--a3632";
export var feedbackDescription = "article-rating-module--feedbackDescription--3f1fe";
export var feedbackTextarea = "article-rating-module--feedbackTextarea--2d8e3";
export var feedbackTitle = "article-rating-module--feedbackTitle--cb61c";
export var ratingWrapper = "article-rating-module--ratingWrapper--4a46f";
export var reviewSubmitResponse = "article-rating-module--reviewSubmitResponse--4e6d6";
export var starIcon = "article-rating-module--starIcon--5fb87";
export var starStatusText = "article-rating-module--starStatusText--51f71";
export var starText = "article-rating-module--starText--88b9e";
export var starsContainer = "article-rating-module--starsContainer--3db7f";