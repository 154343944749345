import React from "react"
import {
  SocialShareWrapper,
  SocialShareWrapperInner,
  Facebook,
  SocialName,
  LinkedIn,
  Twitter,
  WhatsApp,
} from "./social-share.module.css"
import { FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa"
import { dataLayer } from "../../modules/analytics"

const SocialShare = ({ title, description, url }) => {
  const trackShare = async (provider) => {
    dataLayer({
      event: "website-interaction",
      category: "social-share",
      action: "share",
      label: provider,
    })
  }

  const shareFacebook = () => {
    trackShare("facebook")
    const finalUrl =
      "https://www.facebook.com/sharer/sharer.php?display=popup&u=" + url
    const options = "toolbar=0,status=0,resizable=1,width=630,height=820"
    window.open(finalUrl, "sharer", options)
  }

  const shareTwitter = () => {
    trackShare("twitter")
    const finalUrl = `https://twitter.com/intent/tweet?text=${title}&url=${url}`
    const options = "toolbar=0,status=0,resizable=1,width=630,height=500"
    window.open(finalUrl, "sharer", options)
  }

  const shareLinkedin = () => {
    trackShare("linkedin")
    const finalUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}&source=`
    const options = "toolbar=0,status=0,resizable=1,width=630,height=820"
    window.open(finalUrl, "sharer", options)
  }

  const shareWhatsapp = () => {
    trackShare("whatsapp")
    const finalUrl = `https://api.whatsapp.com/send?text=${description} ${url}`
    const options = "toolbar=0,status=0,resizable=1,width=780,height=648"
    window.open(finalUrl, "sharer", options)
  }

  return (
    <div className={SocialShareWrapper}>
      Delen:
      <div className={SocialShareWrapperInner}>
        <div
          className={Facebook}
          onClick={shareFacebook}
          role="button"
          tabindex="0"
          aria-hidden="true"
        >
          <FaFacebook />
          {/* <div className={SocialName}>Facebook</div> */}
        </div>
        <div
          className={LinkedIn}
          onClick={shareLinkedin}
          role="button"
          tabindex="0"
          aria-hidden="true"
        >
          <FaLinkedin />
          {/* <div className={SocialName}>LinkedIn</div> */}
        </div>
        <div
          className={Twitter}
          onClick={shareTwitter}
          role="button"
          tabIndex="0"
          aria-hidden="true"
        >
       
          <div className={SocialName}>X</div>
        </div>
        <div
          className={WhatsApp}
          onClick={shareWhatsapp}
          role="button"
          tabIndex="0"
          aria-hidden="true"
        >
          <FaWhatsapp />
          {/* <div className={SocialName}>WhatsApp</div> */}
        </div>
      </div>
    </div>
  )
}

export default SocialShare
