import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { sponsorLogo,sponsorText } from "./small-sponsor.module.css"
import { trackExternalLink } from "../../services/tracking"
import { getUid } from "../../services/auth"
import { dataLayer } from "../../modules/analytics"
import MediaImage from "../media-image/media-image"

const SmallSponsor = ({ sponsor }) => {
  const trackEvent = async () => {
    dataLayer({
      event: "website-interaction",
      category: "sponsorbox",
      action: `external-link:${sponsor.url}`,
      label: sponsor.name,
    })
    trackExternalLink(await getUid(), sponsor.contentful_id, sponsor.url)
  }
  if (!sponsor) return null
  return (
    <div>
      <p className={sponsorText}>Mede mogelijk gemaakt door onze partner:</p>
      <a
        id="sponsor"
        href={sponsor.url}
        rel="sponsored noopener noreferrer"
        target="_blank"
        onClick={trackEvent}
      >
        {sponsor.image && (
          <MediaImage image={sponsor.image} className={sponsorLogo} alt={sponsor.name} />
        )}
      </a>
    </div>
  )
}

export default SmallSponsor
